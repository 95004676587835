import { gsap, Circ, Power4, Expo } from "gsap";

export class Navigation {
	constructor(options = {}) {
		this.options = options;
		this.cursor = options.cursor;
		this.portfolio = options.portfolio;

		this.header = document.getElementById("header");
		this.nav = document.getElementById("nav");
		this.content = document.getElementById("content");
		this.nav_links = document.querySelectorAll(".nav__link");

		this.nav_timeline = gsap.timeline();
		this.bindEvents();
	}

	bindEvents() {
		this.nav_links.forEach((link) => {
			link.addEventListener(
				"click",
				(event) => {
					if (this.portfolio.barba.history.current.ns == "home") {
						if (link.dataset.section) {
							link.classList.add("is-active");
							this.portfolio.smooth.scrollTo("#" + link.dataset.section, {
								easing: (x) => (x === 1 ? 1 : 1 - Math.pow(2, -10 * x)),
								duration: 2.5,
							});
						}

						event.preventDefault();
						return false;
					}
				},
				false
			);

			link.addEventListener("mouseenter", (event) =>
				this.lockCursor(event.target, true)
			);
			link.addEventListener("mouseleave", (event) =>
				this.lockCursor(event.target, false)
			);
		});

		window.addEventListener("scroll", this.handleScroll);
	}

	handleScroll() {
		if (window.scrollY > window.innerHeight * 0.4) {
			if (this.nav_timeline) this.nav_timeline.kill();
			this.nav_timeline = gsap.timeline();

			this.nav_timeline

				.to(
					".logo__img",
					{
						y: -30,
						ease: Circ.easeOut,
						duration: 2,
					},
					"sync"
				)
				.to(
					this.nav,
					{
						y: -30,
						ease: Circ.easeOut,
						duration: 0.5,
					},
					"sync"
				)
				.to(
					".logo__dash",
					{
						left: -5,
						width: 50,

						ease: Circ.easeOut,
						duration: 2,
					},
					"sync"
				);
		} else {
			if (this.nav_timeline) this.nav_timeline.kill();
			this.nav_timeline = gsap.timeline();

			this.nav_timeline
				.to(
					".logo__img",
					{
						y: 0,
						ease: Circ.easeOut,
						duration: 0.8,
					},
					"sync"
				)
				.to(
					this.nav,
					{
						y: 0,
						ease: Circ.easeOut,
						duration: 1,
					},
					"sync"
				)
				.to(
					".logo__dash",
					{
						left: 63,
						width: 15,
						ease: Circ.easeOut,
						duration: 1,
					},
					"sync"
				);
		}
	}

	lockCursor(target, state) {
		this.cursor.element.className = "";

		if (state) {
			this.cursor.refresh();
			this.cursor.set_state = "locked";

			this.cursor.timeline
				.to(this.cursor.element, {
					duration: 0.4,
					translateX: target.getBoundingClientRect().left,
					translateY:
						target.getBoundingClientRect().top + this.cursor.options.size,
					ease: Expo.easeOut,
					delay: 0.1,
				})
				.to(this.cursor.element, {
					duration: 0.4,
					width: target.getBoundingClientRect().width,
					ease: Expo.easeOut,
				});
		} else {
			this.cursor.setStateFollow(this.cursor.cursor_x, this.cursor.cursor_y);
		}
	}

	init() {
		this.in();
	}

	in() {
		this.nav_timeline
			.from(".logo__wrap", {
				y: 70,
				ease: Circ.easeOut,
				duration: 0.8,
				scale: 0.96,
				onStart: () => {
					this.header.classList.add("is-inactive");
				},
			})
			.to(".nav__dash", {
				width: "100%",
				ease: Power4.easeInOut,
				duration: 0.7,
				delay: -0.3,
			})

			.from(".nav__title", {
				width: "0%",
				ease: Circ.easeOut,
				duration: 1,
			})

			.from(".logo__dash", {
				width: "0px",
				ease: Circ.easeOut,
				duration: 0.8,
				delay: -0.7,
			})

			.to(".nav__title", {
				width: "100%",
				ease: Circ.easeOut,
				duration: 0.7,
			})

			.to(".nav__dash", {
				left: "100%",
				ease: Power4.easeInOut,
				duration: 0.8,
				delay: -1.8,
				onComplete: () => {
					this.header.classList.remove("is-inactive");
				},
			});
	}
}
