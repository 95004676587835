import { gsap, Circ, Power4, Expo } from "gsap";

export class Cursor {
	constructor(options = {}) {
		this.options = options;

		this.element = document.getElementById("cursor");
		this.timeline = gsap.timeline();

		this.state = null;
		this.eye = gsap.timeline();

		this.cursor_x = 0;
		this.cursor_y = 0;

		this.skills_timeline = gsap.timeline();

		this.setup();
	}

	setup() {
		this.bindEvents();
		this.state = "setup";
	}

	init() {
		window.dispatchEvent(new Event("mousemove"));
		this.setStateFollow(this.cursor_x, this.cursor_y);
	}

	set set_state(state) {
		this.state = state;
	}

	refresh() {
		this.timeline.kill();
		this.timeline = gsap.timeline();
	}

	workTrigger(state, target) {
		if (state == true) {
			this.eye.kill();
			this.eye = gsap.timeline();

			this.eye
				.to("#eye__insight", {
					width: "101%",
					height: "101%",
					duration: 1,
					ease: Power4.easeInOut,
					delay: 0.5,
				})
				.to("#cursor", {
					scale: 0.9,
					duration: 0.3,
					ease: Power4.easeIn,
					delay: -0.4,
				});
		} else {
			this.eye.kill();
			this.eye = gsap.timeline();

			this.eye
				.to(
					"#eye__insight",
					{
						width: "0%",
						height: "0%",
						duration: 0.3,
					},
					"sync"
				)
				.to(
					"#cursor",
					{
						scale: 1,
						duration: 0.3,
						ease: Power4.easeIn,
						onComplete: () => {
							this.state = "follow";

							gsap.to(this.element, {
								duration: this.options.speed,
								width: this.options.size,
								height: this.options.size,
								left: 0,
								top: 0,
								rotate: "0deg",

								ease: Expo.easeOut,
								borderRadius: 0,
								backgroundColor: "#1A44C5",
							});
						},
					},
					"sync"
				);
		}
	}

	reset() {
		this.element.className = "";

		gsap.to(this.element, {
			duration: this.options.speed,
			width: this.options.size,
			height: this.options.size,
			left: 0,
			top: 0,
			rotate: "0deg",
			ease: Expo.easeOut,
			borderRadius: 0,
			backgroundColor: "#1A44C5",
		});
	}

	setStateFollow(x, y) {
		gsap.to(this.element, {
			duration: this.options.speed,
			width: this.options.size,
			height: this.options.size,
			translateX: x,
			translateY: y,
			left: 0,
			top: 0,
			rotate: "0deg",
			background: "#1A44C5",
			border: "none",
			ease: Expo.easeOut,
			borderRadius: 0,
			onStart: () => {
				this.element.className = "";
			},
		});
		this.state = "follow";
	}

	setStateSystem(x, y) {
		this.skills_timeline.kill();
		this.skills_timeline = gsap.timeline();

		this.skills_timeline.to(this.element, {
			duration: this.options.speed,
			width: 70,
			height: 70,
			translateX: x,
			translateY: y,
			left: -35,
			top: -35,
			background: "transparent",
			border: "5px solid #1A44C5",
			rotate: "225deg",
			ease: Expo.easeOut,
			borderRadius: 0,
		});
		this.state = "system";
	}

	setStateTech(x, y) {
		this.skills_timeline.kill();
		this.skills_timeline = gsap.timeline();

		this.skills_timeline.to(this.element, {
			duration: this.options.speed,
			width: 50,
			height: 50,
			translateX: x,
			translateY: y,
			left: -25,
			top: -25,
			background: "transparent",
			border: "5px solid #1A44C5",
			rotate: "135deg",
			ease: Expo.easeOut,
			borderRadius: 0,
		});
		this.state = "tech";
	}

	setStateDesign(x, y) {
		this.skills_timeline.kill();
		this.skills_timeline = gsap.timeline();

		this.skills_timeline.to(this.element, {
			duration: this.options.speed,
			width: 30,
			height: 30,
			translateX: x,
			translateY: y,
			left: -15,
			top: -15,
			rotate: "45deg",
			background: "transparent",
			border: "5px solid #1A44C5",
			ease: Expo.easeOut,
			borderRadius: 0,
			onStart: () => {
				this.element.className = "";
			},
		});
		this.state = "design";
	}

	bindEvents() {
		window.addEventListener("mousemove", (e) => {
			this.cursor_x = e.clientX;
			this.cursor_y = e.clientY;

			switch (this.state) {
				case "design":
					this.setStateDesign(this.cursor_x, this.cursor_y);
					break;

				case "tech":
					this.setStateTech(this.cursor_x, this.cursor_y);
					break;

				case "system":
					this.setStateSystem(this.cursor_x, this.cursor_y);
					break;

				case "setup":
					gsap.to(this.element, {
						duration: 0.0,
						translateX: this.cursor_x,
						translateY: this.cursor_y,
						left: "10px",
						top: "10px",
					});

					break;

				case "work":
					gsap.to(this.element, {
						duration: this.options.speed,
						width: 150,
						height: 150,
						translateX: this.cursor_x,
						translateY: this.cursor_y,
						left: -100,
						top: -100,
						borderRadius: 999,
						ease: Expo.easeOut,
						onStart: () => {
							this.element.classList.add("eye-open");
						},
					});

					break;

				case "follow":
					this.setStateFollow(this.cursor_x, this.cursor_y);
					break;

				case "head":
					gsap.to(this.element, {
						duration: this.options.speed,
						translateX: this.cursor_x,
						translateY: this.cursor_y,
						ease: Expo.easeOut,
					});

					break;

				case "locked":
					break;
			}
		});
	}
}
