
import * as THREE from 'three';
import { gsap, Power4 } from "gsap";

class EffectShell {
  constructor() {
    this.setup()

    this.shown = false

    this.initEffectShell().then(() => {
     
      this.isLoaded = true
      if (this.isMouseOver) this.onMouseOver(this.tempItemIndex)
      this.tempItemIndex = null

      
      
    })
    this.createEventsListeners()
  }


  setup() {
    window.addEventListener('resize', this.onWindowResize.bind(this), false)

    // renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    this.renderer.setSize(this.viewport.width, this.viewport.height)
    this.renderer.setPixelRatio(window.devicePixelRatio)
    document.body.appendChild(this.renderer.domElement)

    // scene
    this.scene = new THREE.Scene()

    // camera
    this.camera = new THREE.PerspectiveCamera(
      40,
      this.viewport.aspectRatio,
      0.1,
      100
    )
    this.camera.position.set(0, 0, 3)

    //mouse
    this.mouse = new THREE.Vector2()
      
    this.timeSpeed = 2
    this.time = 0
    this.clock = new THREE.Clock()

    // animation loop
    this.renderer.setAnimationLoop(this.render.bind(this))
  }

  render() {
    // called every frame
    this.time += this.clock.getDelta() * this.timeSpeed
    this.renderer.render(this.scene, this.camera)
  }

  initEffectShell() {
    let promises = []

    this.items = this.itemsElements


    const THREEtextureLoader = new THREE.TextureLoader()
    this.items.forEach((item, index) => {

      // create textures
      promises.push(
        this.loadTexture(
          THREEtextureLoader,
          item.img.src,
          index
        )
      )
    })

    return new Promise((resolve, reject) => {
      // resolve textures promises
      Promise.all(promises).then(promises => {
        // all textures are loaded
        promises.forEach((promise, index) => {
          // assign texture to ite
          this.items[index].tex = promise.tex
        })
        resolve()
      })
    })
  }

  createEventsListeners() {

    document.body.addEventListener(
      'mousemove',
      this._onMouseMove.bind(this),
      false
    )
    document.body.addEventListener('click', event => {

      const months = [0, 1, 2];
      const random = Math.floor(Math.random() * months.length);

      this.onMouseOver(random, null)
    })

    


 

  }


  _onMouseMove(event) {
    // get normalized mouse position on viewport
    this.mouse.x = (event.clientX / this.viewport.width) * 2 - 1
    this.mouse.y = -(event.clientY / this.viewport.height) * 2 + 1

    this.onMouseMove(event)
  }

  _onMouseOver(index, event) {
    this.tempItemIndex = index
    this.onMouseOver(index, event)
  }

  onWindowResize() {
    this.camera.aspect = this.viewport.aspectRatio
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(this.viewport.width, this.viewport.height)
  }

  onUpdate() {}

  onMouseEnter(event) {}


  onMouseMove(event) {}

  onMouseOver(index, event) {}

  get viewport() {
    let width = window.innerWidth
    let height = window.innerHeight
    let aspectRatio = width / height
    return {
      width,
      height,
      aspectRatio
    }
  }

  get viewSize() {
    // fit plane to screen
    // https://gist.github.com/ayamflow/96a1f554c3f88eef2f9d0024fc42940f

    let distance = this.camera.position.z
    let vFov = (this.camera.fov * Math.PI) / 180
    let height = 2 * Math.tan(vFov / 2) * distance
    let width = height * this.viewport.aspectRatio
    return { width, height, vFov }
  }

  get itemsElements() {
    // convert NodeList to Array
    const items = [document.body.querySelectorAll('.test')]


    var temp_items = []
    document.body.querySelectorAll('.test').forEach((item, index) => {

      // create textures
      temp_items.push({
          img: item,
          index: index
        })

    })

    return temp_items


  }

  loadTexture(loader, url, index) {
    // https://threejs.org/docs/#api/en/loaders/TextureLoader
    return new Promise((resolve, reject) => {
      if (!url) {
        resolve({ tex: null, index })
        return
      }
      // load a resource
      loader.load(
        // resource URL
        url,

        // onLoad callback
        tex => {
          resolve({ tex, index })
        },

        // onProgress callback currently not supported
        undefined,

        // onError callback
        error => {
          console.error('An error happened.', error)
          reject(error)
        }
      )
    })
  }
}

export class RGBShiftEffect extends EffectShell {
  constructor(options = {}) {

    super()

    options.strength = options.strength || 0.25
    this.options = options

    this.scale_timeline = gsap.timeline();
    this.head_h2_timeline = gsap.timeline();

    this.init()

    this.onMouseOver(0, null)

  
    
  }

  init() {
    this.position = new THREE.Vector3(0, 0, 0)
    this.scale = new THREE.Vector3(0, 0, 0)
    this.geometry = new THREE.PlaneGeometry(1, 1, 32, 32)
    this.uniforms = {
      uTime: {
        value: 0
      },
      uTexture: {
        value: null
      },
      uOffset: {
        value: new THREE.Vector2(0.0, 0.0)
      },
      uAlpha: {
        value: 0
      }
    }
    this.material = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      transparent : true,
      blending: THREE.NormalBlending,
      
      vertexShader: `
        uniform vec2 uOffset;

        varying vec2 vUv;

        vec3 deformationCurve(vec3 position, vec2 uv, vec2 offset) {
          float M_PI = 3.1415926535897932384626433832795;
          position.x = position.x + (sin(uv.y * M_PI) * offset.x);
          position.y = position.y + (sin(uv.x * M_PI) * offset.y);
          return position;
        }

        void main() {
          vUv = uv;
          vec3 newPosition = position;
          newPosition = deformationCurve(position,uv,uOffset);
          gl_Position = projectionMatrix * modelViewMatrix * vec4( newPosition, 1.0 );
        }
      `,
      fragmentShader: `
        uniform sampler2D uTexture;
        uniform float uAlpha;
        uniform vec2 uOffset;

        varying vec2 vUv;

        vec3 rgbShift(sampler2D tex, vec2 uv, vec2 offset) {
          float r = texture2D(uTexture,vUv + uOffset).r;
          vec2 gb = texture2D(uTexture,vUv).gb;
          return vec3(r,gb);
        }

        void main() {
          vec3 color = texture2D(uTexture,vUv).rgb;
          gl_FragColor = vec4(color,uAlpha);
        }
      `,
      transparent: true
    })
    this.plane = new THREE.Mesh(this.geometry, this.material)
    this.scene.add(this.plane)
    gsap.to(this.plane.scale, {
      x: 0,
      y: 0,
      z: 0,
      duration: .0,
      ease: Power4.easeOut,
      onUpdate: this.onPositionUpdate.bind(this)
    });

    var i = 0
    setInterval(() => {

      if(!this.shown) return
      i += 1
      this.onMouseOver(i % this.items.length, null)

    }, 400);

  }




  show() {

    this.shown = true

    this.scale_timeline.kill();
    this.scale_timeline = gsap.timeline();

    this.scale_timeline.to(this.plane.scale, {
      x: 2,
      y: 1.5,
      z: 1,
      duration: .4,
      ease: Power4.easeOut,
      onUpdate: this.onPositionUpdate.bind(this)
    });

  }

  hide() {

    this.scale_timeline.kill();
    this.scale_timeline = gsap.timeline();

    this.head_h2_timeline.kill();
    this.head_h2_timeline = gsap.timeline();

    var head_h2 = document.querySelectorAll('.hero__headlines-wrap h2');

    this.head_h2_timeline.to(head_h2, {
      
      color : "#1A44C5",
      duration : .2

    });
    
    this.shown = false
    this.scale_timeline.to(this.plane.scale, {
      x: 0,
      y: 0,
      z: 0,
      duration: .4,
      ease: Power4.easeOut,
      onUpdate: this.onPositionUpdate.bind(this),
      onComplete: () => {  }
    });


  }

  onMouseEnter() {
    if (!this.isMouseOver) {
      this.isMouseOver = true
      // show plane
      gsap.to(this.uniforms.uAlpha, 0.5, {
        value: 1,
        ease: Power4.easeOut
      })
    }
  }



  onMouseMove(event) {
    // project mouse position to world coodinates
    let x = this.mouse.x.map(
      -1,
      1,
      -this.viewSize.width / 2,
      this.viewSize.width / 2
    )
    let y = this.mouse.y.map(
      -1,
      1,
      -this.viewSize.height / 2,
      this.viewSize.height / 2
    )

    this.position = new THREE.Vector3(x, y, 0)
    gsap.to(this.plane.position, 1, {
      x: x,
      y: y,
      ease: Power4.easeOut,
      onUpdate: this.onPositionUpdate.bind(this)
    })
  }

  onPositionUpdate() {
    // compute offset
    let offset = this.plane.position
      .clone()
      .sub(this.position)
      .multiplyScalar(-this.options.strength)
    this.uniforms.uOffset.value = offset
  }

  onMouseOver(index, e) {
    if (!this.isLoaded) return
    this.onMouseEnter()
    if (this.currentItem && this.currentItem.index === index) return
    this.onTargetChange(index)
  }

  onTargetChange(index) {

    if(!this.shown) return

    var colors = [

      "rgba(0,0,0,0)",
      "#deff00",
      "rgba(0,0,0,0)",
      "#FD3D00",
      "rgba(0,0,0,0)",
      

    ]


    var head_h2 = document.querySelectorAll('.hero__headlines-wrap h2');
    head_h2.forEach(link => {
    
      this.head_h2_timeline.to(link, {

        color : colors[index],
        duration : 0


      })
    
    });

    this.currentItem = this.items[index]
    if (!this.currentItem.tex) return
    
    // compute image ratio
    let imageRatio =
      this.currentItem.img.naturalWidth / this.currentItem.img.naturalHeight

    this.uniforms.uTexture.value = this.currentItem.tex
    //this.plane.scale.copy(this.scale)
  }



}

Number.prototype.map = function(in_min, in_max, out_min, out_max) {
  return ((this - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
}

