import LocomotiveScroll from "locomotive-scroll";
import barba from "@barba/core";

import Lenis from "@studio-freight/lenis";

import { Navigation } from "./navigation.js";
import { Cursor } from "./cursor.js";
import { Sections } from "./section.js";

import { gsap, Circ, Power4, Expo } from "gsap";

export class Portfolio {
	constructor(options = {}) {
		this.cursor = new Cursor({ size: 10, speed: 0.5 });
		this.navigation = new Navigation({ cursor: this.cursor, portfolio: this });
		this.sections = new Sections({ cursor: this.cursor, portfolio: this });

		this.preload = document.getElementById("preload");
		this.options = options;

		this.scroll = null;
		this.scroll_stop = false;

		this.smooth = new Lenis();
		this.scroll = new LocomotiveScroll();

		this.barba = barba;
		requestAnimationFrame((time) => {
			this.raf(time);
		});

		this.setup();
	}
	raf(time) {
		this.smooth.raf(time);
		requestAnimationFrame((time) => {
			this.raf(time);
		});
	}

	setup() {
		this.barba.init({
			transitions: [
				{
					name: "home-to-work",

					from: { namespace: ["home"] },
					to: { namespace: ["work"] },

					leave: (data) => {
						this.smooth.stop();

						let enter_timeline = gsap.timeline();

						let preview_work = document.createElement("div");
						let transition_dash = document.createElement("div");
						let transition_overlay = document.createElement("div");

						preview_work.id = "preview_work";
						transition_dash.id = "transition_dash";
						transition_overlay.id = "transition_overlay";

						preview_work.style.width =
							data.trigger.getClientRects()[0].width + "px";
						preview_work.style.height =
							data.trigger.getClientRects()[0].height + "px";
						preview_work.style.backgroundImage =
							"url(" + data.trigger.style.backgroundImage.split('"')[1] + ")";

						preview_work.style.transform =
							"translate(" +
							data.trigger.getClientRects()[0].left +
							"px, " +
							data.trigger.getClientRects()[0].top +
							"px)";

						document.querySelectorAll(".work__preview").forEach((work) => {
							work.classList.add("is-disabled");
						});

						document.querySelectorAll(".work__wrap").forEach((work) => {
							if (work.dataset.init == "true") {
								this.sections.workTimelines[work.id]
									.delay(-0.2)
									.timeScale(2)
									.reverse();
							}
						});

						document.body.prepend(preview_work);
						document.body.prepend(transition_dash);
						document.body.prepend(transition_overlay);

						this.sections.startCycle(data.trigger);

						gsap.to(".line", {
							height: 0,
							duration: 1,
							ease: Power4.easeInOut,
						});

						return enter_timeline

							.to(".work__preview", {
								opacity: 0,
								duration: 1,
							})

							.to(preview_work, {
								x:
									window.innerWidth / 2 -
									preview_work.getClientRects()[0].width / 2,
								y:
									window.innerHeight / 2 -
									preview_work.getClientRects()[0].height / 2,
								duration: 0.7,
								scale: 0.9,
								ease: Power4.easeInOut,
								delay: -1.1,
							})
							.from(transition_overlay, {
								opacity: 0,
								ease: Power4.easeInOut,
							})
							.to(transition_dash, {
								width: "100%",
								duration: 0.5,
								ease: Power4.easeInOut,
								delay: -0.5,
								onComplete: () => {
									document
										.querySelectorAll(".nav__link")
										.forEach((link) => (link.style.display = "none"));
									document.getElementById("back").style.display = "block";
								},
							})

							.to(data.current.container, {
								opacity: 0,
								duration: 0.5,

								onComplete: () => {
									data.current.container.remove();
								},
							});
					},

					beforeEnter: (data) => {
						this.smooth.start();
						this.smooth.scrollTo(0, { immediate: true });
						this.smooth.stop();

						let beforeEnter_timeline = gsap.timeline();

						let preview_work = document.getElementById("preview_work");
						let transition_dash = document.getElementById("transition_dash");
						let transition_overlay =
							document.getElementById("transition_overlay");

						let hero_work = data.next.container.querySelector("#work__hero");
						let hero_work_attr = hero_work.getClientRects()[0];

						return beforeEnter_timeline
							.to(
								document.getElementById("transition_overlay"),
								{
									opacity: 0,
									ease: Circ.easeOut,
									duration: 1,
									onStart: () => {
										hero_work.style.backgroundImage =
											"url(" +
											preview_work.style.backgroundImage.split('"')[1] +
											")";
										clearInterval(this.sections.cycleInterval);
									},
								},
								"sync"
							)
							.to(
								preview_work,
								{
									width: hero_work_attr.width,
									height: hero_work_attr.height,
									x: hero_work_attr.left,
									y: hero_work_attr.top,
									backgroundSize: "100% auto",
									duration: 0.7,
									scale: 1,
									ease: Expo.easeInOut,
									delay: -0.2,
								},
								"sync"
							)

							.from(data.next.container, {
								opacity: 0,

								onComplete: () => {
									document.getElementById("transition_dash").remove();
									this.smooth.start();
									preview_work.remove();
									transition_dash.remove();
									transition_overlay.remove();
								},
							});
					},

					enter: (data) => {},
				},

				{
					name: "default",

					leave: (data) => {
						return gsap.to(data.current.container, {
							opacity: 0,
							onComplete: () => {
								data.current.container.remove();
							},
						});
					},

					enter: (data) => {
						return gsap.from(data.next.container, {
							opacity: 0,
						});
					},
				},

				{
					name: "back-to-home",

					from: {
						custom: ({ trigger }) => {
							return trigger.dataset.workReturn;
						},
					},

					leave: (data) => {
						return gsap.to(data.current.container, {
							opacity: 0,
							onComplete: () => {
								data.current.container.remove();
							},
						});
					},

					enter: (data) => {
						this.smooth.destroy();
						this.smooth = new Lenis();

						let work = document.getElementById(
							data.current.container.dataset.workId
						);
						let work_preview = work.querySelectorAll(".work__preview")[0];

						document
							.querySelectorAll(".nav__link")
							.forEach((link) => (link.style.display = "block"));
						document.getElementById("back").style.display = "none";

						this.smooth.scrollTo(
							work_preview.getClientRects()[0].y - window.innerHeight * 0.25,
							{ immediate: true }
						);

						return gsap.from(data.next.container, {
							opacity: 0,
						});
					},
				},
			],

			views: [
				{
					namespace: "home",
					beforeEnter: () => {
						this.scroll.init();
						this.smooth.scrollTo(0);

						this.sections.bindEvents();
						this.bindEvents();
					},
					afterLeave: () => {},
				},
				{
					namespace: "work",
					beforeEnter: () => {
						this.scroll.init();

						this.sections.bindEvents();
						this.bindEvents();
					},
					afterEnter: () => {},
				},
			],
		});

		//if(this.options.direct_start) preload.remove()
		this.init();
	}

	bindEvents() {}

	yolo = (asd) => {};

	init() {
		this.navigation.init();
		this.cursor.init();
	}
}
