import { gsap, Circ, Power4, Expo } from "gsap";
import { EffectShell, RGBShiftEffect } from "./effect_shell.js";

import { ScrollTrigger } from "gsap/ScrollTrigger";

import { TextTransition } from "./split_text.js";

export class Sections {
	constructor(options = {}) {
		gsap.registerPlugin(ScrollTrigger);

		this.options = options;
		this.cursor = options.cursor;
		this.portfolio = options.portfolio;

		this.content = document.getElementById("content");
		this.hero = document.getElementById("hero");
		this.hero_headlines = document.querySelectorAll(".hero__headlines")[0];

		this.skill_headlines = [...document.querySelectorAll(".skill-headline")];

		this.hero_timeline = gsap.timeline();

		//this.hero.classList.add("is-inactive");
		//this.hero_effect = new RGBShiftEffect({ strength: .4 })

		this.cycleInterval = null;
		this.sliderTimeline = gsap.timeline();

		this.workTimelines = {};
	}

	init() {}

	startCycle(target) {
		let currentIndex = 0;

		var imageElements = target.querySelectorAll("img");
		var imageArray = Array.from(imageElements).map((img) => img.src);

		this.cycleInterval = setInterval(() => {
			document.getElementById("preview_work").style.backgroundImage =
				"url(" + imageArray[currentIndex] + ")";
			currentIndex = (currentIndex + 1) % imageArray.length;
		}, 300);
	}

	bindEvents() {
		let prevSlide = null;
		let skill_info = new TextTransition();

		skill_info.currentTextPos = 100;

		this.sliderTimeline.kill();
		this.sliderTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: "#skills",
				start: "center center",
				pin: true,
				end: "+=4500",
				scrub: false,
				onEnter: () => {},
				onEnterBack: () => {},
				onLeaveBack: () => {
					document
						.querySelectorAll(".skill-headline.is-active")[0]
						.classList.remove("is-active");
					prevSlide = null;
					this.cursor.setStateFollow(
						this.cursor.cursor_x,
						this.cursor.cursor_y
					);
				},
				onLeave: () => {
					this.skill_headlines[2].classList.remove("is-active");
					prevSlide = null;
					this.cursor.setStateFollow(
						this.cursor.cursor_x,
						this.cursor.cursor_y
					);
				},
				onUpdate: (self) => {
					const progress = self.progress;
					const currentSlide = Math.floor(progress * 3); // Assuming 3 slides

					this.skill_headlines = [
						...document.querySelectorAll(".skill-headline"),
					];

					if (currentSlide < 3) {
						switch (currentSlide) {
							case 0:
								this.cursor.setStateDesign(
									this.cursor.cursor_x,
									this.cursor.cursor_y
								);

								break;
							case 1:
								this.cursor.setStateTech(
									this.cursor.cursor_x,
									this.cursor.cursor_y
								);
								break;
							case 2:
								this.cursor.setStateSystem(
									this.cursor.cursor_x,
									this.cursor.cursor_y
								);
								break;
						}

						skill_info.switchText(currentSlide);
						prevSlide = currentSlide;

						if (
							document.querySelectorAll(".skill-headline.is-active").length != 0
						) {
							document
								.querySelectorAll(".skill-headline.is-active")[0]
								.classList.remove("is-active");
						}
						this.skill_headlines[currentSlide].classList.add("is-active");
					}
				},
			},
		});

		// SECTION IN-VIEW OBSERVER

		this.portfolio.scroll.on("call", (func, dir, target) => {
			if (target && target.targetEl.id) {
				let nav_link = document.querySelector(
					'.nav__link[data-section="' + target.targetEl.id + '"]'
				);
				if (nav_link != null) {
					switch (dir) {
						case "enter":
							gsap.to(nav_link.querySelector(".nav__dash"), {
								left: 0,
								ease: Circ.easeOut,
								onStart: () => {
									nav_link.classList.add("is-active");
								},
							});
							break;

						case "exit":
							gsap.to(nav_link.querySelector(".nav__dash"), {
								left: "100%",
								ease: Circ.easeOut,
								onComplete: () => {
									nav_link.classList.remove("is-active");
								},
							});
							break;
					}
				}
			}

			switch (func) {
				case "inObserver":
					if (target.el.dataset.init == "false") {
						switch (target.el.dataset.in) {
							case "heroIn":
								this.heroIn();
								break;

							case "workIn":
								this.workIn(target.el);
								break;

							case "skillsIn":
								this.skillsIn();
								break;

							case "clientsIn":
								this.clientsIn();
								break;
						}

						target.el.dataset.init = "true";
					}

					break;
			}
		});

		// WORK THUMBNAIL HOVER

		const work_hover = document.querySelectorAll(".work__preview");
		work_hover.forEach((link) => {
			link.addEventListener("mouseenter", (event) => {
				this.cursor.workTrigger(true, event.target);
				this.cursor.state = "work";
			});
			link.addEventListener("mouseleave", (event) => {
				var imageElements = event.target.querySelectorAll("img");
				var imageArray = Array.from(imageElements).map(function (img) {
					return img.src;
				});

				this.cursor.workTrigger(false, event.target);
				this.cursor.reset();

				event.target.style.backgroundImage = "url(" + imageArray[0] + ")";
				this.cursor.setStateFollow(this.cursor.cursor_x, this.cursor.cursor_y);
			});
		});

		// HERO HEADLINES HOVER
		this.hero_headlines.addEventListener("mouseenter", (event) => {
			return;
			this.hero_effect.show();

			if (this.hero_timeline) this.hero_timeline.kill();
			this.hero_timeline = gsap.timeline();

			this.hero_timeline
				.to(
					".hero__dash",
					{
						width: 0,
						ease: Power4.easeIn,
						duration: 0.1,
					},
					"sync"
				)
				.to(
					"#scrollin, .hero__teaser",
					{
						opacity: 0,
						y: -10,
						ease: Power4.easeIn,
						duration: 0.1,
					},
					"sync"
				);

			cursor.style.display = "none";
		});
		this.hero_headlines.addEventListener("mouseleave", (event) => {
			return;
			this.hero_effect.hide();

			if (this.hero_timeline) this.hero_timeline.kill();
			this.hero_timeline = gsap.timeline();

			this.hero_timeline
				.to(
					".hero__dash",
					{
						width: 30,
						ease: Power4.easeOut,
						duration: 0.6,
					},
					"sync"
				)
				.to(
					"#scrollin, .hero__teaser",
					{
						opacity: 1,
						y: 0,
						ease: Power4.easeOut,
						duration: 0.6,
					},
					"sync"
				);

			cursor.style.display = "block";
		});
	}

	skillsIn() {
		gsap.from(".skill-info-wrapper", {
			opacity: 0,
			y: 30,
			duration: 1,
		});

		gsap.from(".skill-headline", {
			width: 0,
			duration: 0.2,

			ease: Circ.easeOut,
			stagger: 0.2,
		});
	}

	heroIn() {
		gsap.from(".line:nth-child(3)", {
			top: 0,
			duration: 1,
			ease: Power4.easeOut,
		});

		this.hero_timeline
			.from(
				"#scrollin",
				{
					rotate: "-10deg",
					opacity: 0,
					y: 5,
					ease: Circ.easeOut,
					duration: 1,
					delay: 0.5,
				},
				"sync"
			)
			.to(
				".hero__headlines-dash",
				{
					width: "100%",
					ease: Power4.easeInOut,
					duration: 1,
					delay: 0.3,
					onStart: () => {
						this.hero.classList.add("is-inactive");
					},
				},
				"sync"
			)

			.from(".hero__headlines-wrap h2", {
				width: "0%",
				ease: Power4.easeOut,
				duration: 1,
			})
			.to(".hero__headlines-dash.primary", {
				left: "100%",
				ease: Power4.easeInOut,
				duration: 0.8,
				stagger: 0.4,
				delay: -1.1,
			})

			.to(".hero__headlines-dash.secondary", {
				left: "100%",
				ease: Power4.easeInOut,
				duration: 1.2,
				delay: -0.5,
				onComplete: () => {
					this.hero.classList.remove("is-inactive");
				},
			})

			.from(".hero__dash", {
				width: 0,
				ease: Circ.easeOut,
				duration: 1,
				delay: -0.6,
			})
			.from(".hero__teaser", {
				opacity: 0,
				x: -10,
				ease: Circ.easeOut,
				duration: 2,
				delay: -1.2,
			});
	}

	workIn(target) {
		let work_timeline = gsap.timeline();
		this.workTimelines[target.id] = work_timeline;

		let work_image = target.querySelectorAll(".work__preview");
		let work_image_dash = target.querySelectorAll(".work__preview .work__dash");
		let work_teaser = target.querySelectorAll("h3");
		let work_headline = target.querySelectorAll(".headline-segment__line");
		let work_id = target.querySelectorAll(".work__id");

		work_timeline
			.to(work_image_dash, {
				width: "100%",
				duration: 0,
			})
			.from(
				target,
				{
					y: -20,
					duration: 2.2,
					ease: Expo.easeInOut,
				},
				"sync"
			)
			.to(
				work_image_dash,
				{
					width: "0%",
					duration: 2.2,
					ease: Expo.easeInOut,
				},
				"sync"
			)
			.from(
				work_image,
				{
					backgroundSize: "115% auto",
					duration: 2.0,
					ease: Expo.easeInOut,
				},
				"sync"
			)

			.from(
				work_headline,
				{
					y: 130,
					scale: 0.95,
					duration: 2.0,
					ease: Expo.easeInOut,
					delay: 0.1,
				},
				"sync"
			)
			.from(
				work_teaser,
				{
					opacity: 0,
					scaleX: 1.2,
					delay: -1.5,
					duration: 2.0,
					ease: Expo.easeOut,
				},
				"sync2"
			)
			.from(
				work_id,
				{
					scaleX: 1.5,
					opacity: 0,
					x: -10,
					duration: 1.0,
					delay: -0.9,
					ease: Expo.easeOut,
				},
				"sync2"
			);
	}

	clientsIn() {
		let clients_timeline = gsap.timeline();

		clients_timeline.from(".logo-grid-row div", {
			opacity: 0,
			stagger: 0.2,
			duration: 0.8,
			delay: 0.4,
			scale: 1.1,
			y: 10,
		});
	}
}
