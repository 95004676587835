import { gsap, Circ, Power4, Expo } from "gsap";
import * as THREE from "three";

import { Portfolio } from "./portfolio.js";

const portfolio = new Portfolio({
	direct_start: true,
});

var locomotiveScroll;

const in_timeline = gsap.timeline();

var cursor_timeline = gsap.timeline();
var head_timeline = gsap.timeline();

var head_effect;

const primary = "#1A44C5";
const secondary = "#E7E6E3";
const darkSecondary = "#C1C0BC";

const cursor = document.getElementById("cursor");

const header = document.getElementById("header");
const head_content = document.getElementById("head-content");
const headlines = document.getElementById("headlines");

var cursor_state = "follow";
var cursor_default_size = 10;

function _preload() {
	in_timeline
		.to(".bg-segment", {
			height: "100%",
			ease: Expo.easeOut,
			duration: 0.8,
			delay: 0.5,
		})
		.to("#dash", {
			height: "20px",
			width: "20px",
			backgroundColor: secondary,
			ease: Power4.easeOut,
			duration: 0.6,
			delay: -0.7,
		})
		.to("#meta", {
			opacity: 1,
			duration: 0.7,
			delay: -0.5,
		})
		.to("#O", {
			scale: 0.5,
			duration: 0.2,
		})
		.to("#dash", {
			top: "9px",
			height: "100px",
			ease: Expo.easeOut,
		})
		.to(".circle-segment path", {
			strokeWidth: "16px",
			strokeDashoffset: 0,
			duration: 1,
			ease: Expo.easeOut,
		})
		.to("#dash", {
			height: "18px",
			top: "130px",
			ease: Power4.easeOut,
			duration: 0.9,
			delay: -0.4,
		})
		.to("#dash", {
			ease: Power4.easeOut,
			duration: 0.5,
			delay: -0.65,
		})
		.to("#dash", {
			width: "100px",
			top: "130px",
			duration: 1.5,
			delay: -0.45,
		})
		.to("#dash", {
			width: "200vw",
			ease: Circ.easeIn,
			duration: 0.3,
		})
		.to("#dash", {
			height: "230vh",
			ease: Circ.easeInOut,
			duration: 0.4,
			top: "-100vh",
		})
		.to("#preload", {
			opacity: 0,
			ease: Expo.easeOut,
			duration: 0,
			onComplete: function () {
				direct_start();
			},
		})
		.to("#meta, #meta a", {
			color: darkSecondary,
			duration: 0.4,
			delay: -1.15,
		})
		.to("#meta span", {
			backgroundColor: darkSecondary,
			duration: 0.4,
			delay: -0.4,
		})
		.to("#meta hr", {
			backgroundColor: darkSecondary,
			duration: 0.4,
			delay: -0.4,
		})
		.to("#circle", {
			scale: 0.7,
			duration: 2,
			ease: Expo.easeOut,
			delay: -0.9,
		})
		.to(".bg-segment", {
			backgroundColor: "#06133B",
			ease: Expo.easeOut,
			duration: 3,
			delay: -1.9,
		});
}
