import Splitting from "splitting";
import { gsap, Circ, Power4, Expo } from "gsap";

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

export class TextTransition {
	constructor() {
		this.splitting = Splitting();

		// Let's define the position of the current text
		this.currentTextPos = 100;

		// Check if there's an animation in progress
		this.isAnimating = false;

		this.mainTimeline = gsap.timeline();
		this.bulletTimeline = gsap.timeline();

		// Add class current to the "current" one

		this.wrapElements(
			this.splitting.map((output) => output.words).flat(),
			"span",
			"word-wrap"
		);
	}

	wrapElements(elems, wrapType, wrapClass) {
		elems.forEach((word) => {
			const wrapEl = document.createElement(wrapType);
			wrapEl.classList = wrapClass;

			// Get a reference to the parent
			const parent = word.parentNode;

			// Insert the wrapper before the word in the DOM tree
			parent.insertBefore(wrapEl, word);

			// Move the word inside the wrapper
			wrapEl.appendChild(word);
		});
	}

	// switch between texts
	switchText(upcomingTextPos) {
		if (upcomingTextPos == this.currentTextPos) return false;
		if (this.isAnimating) return false;
		this.isAnimating = true;

		let init = false;

		if (this.currentTextPos == 100) {
			this.currentTextPos = 0;
			init = true;
		}

		let texts = [...document.querySelectorAll(".skill-info")];

		// All current text words
		const currentWords = this.splitting[this.currentTextPos].words;

		// All upcoming text words
		const upcomingtWords = this.splitting[upcomingTextPos].words;
		const upcomingWordsTotal = upcomingtWords.length;

		const current_bullets = [...document.querySelectorAll(".skill-info")][
			this.currentTextPos
		].querySelectorAll(".skill-bullet");

		const next_bullets = [...document.querySelectorAll(".skill-info")][
			upcomingTextPos
		].querySelectorAll(".skill-bullet");

		this.bulletTimeline.kill();
		this.bulletTimeline = gsap.timeline();

		this.bulletTimeline
			.to(current_bullets, {
				opacity: 0,
				duration: 0.5,
				stagger: 0.1,
			})
			.to(next_bullets, {
				opacity: 1,
				duration: 0.5,
				stagger: 0.1,
			});

		this.mainTimeline.kill();
		this.mainTimeline = gsap.timeline({
			defaults: {
				duration: 0.05,
				ease: "expo",
			},
			onComplete: () => {
				// Update currentTextPos
				this.currentTextPos = upcomingTextPos;
				this.isAnimating = false;
			},
		});

		if (!init) {
			this.mainTimeline
				.fromTo(
					currentWords,
					{
						xPercent: 0,
					},
					{
						duration: 0.15,
						ease: "power1.in",
						xPercent: -100,
						stagger: {
							each: 0.01,
							from: "start",
						},
						onComplete: () => {
							texts[this.currentTextPos].classList.remove("current");
						},
					}
				)
				.fromTo(
					currentWords.map((word) => word.parentNode),
					{
						xPercent: 0,
					},
					{
						duration: 0.15,
						ease: "power1.in",
						xPercent: 100,
						stagger: {
							each: 0.01,
							from: "start",
						},
						onComplete: () => {
							texts[this.currentTextPos].classList.remove("current");
						},
					},
					0
				)
				.addLabel("currentPanel", ">-=0.2")

				.fromTo(
					upcomingtWords,
					{
						xPercent: 100,
					},
					{
						onStart: () => {
							texts[upcomingTextPos].classList.add("current");
						},
						duration: 0.6,
						ease: "expo",
						xPercent: 0,
						stagger: {
							each: 0.01,
							from: "start",
						},
					},
					"currentPanel"
				)
				.fromTo(
					upcomingtWords.map((word) => word.parentNode),
					{
						xPercent: -100,
					},
					{
						onStart: () => {
							texts[upcomingTextPos].classList.add("current");
						},
						duration: 0.6,
						ease: "expo",
						xPercent: 0,
						stagger: {
							each: 0.01,
							from: "start",
						},
					},
					"currentPanel"
				);
		} else {
			init = true;

			this.mainTimeline
				.fromTo(
					upcomingtWords,
					{
						xPercent: 100,
					},
					{
						onStart: () => {
							texts[upcomingTextPos].classList.add("current");
						},
						duration: 0.6,
						ease: "expo",
						xPercent: 0,
						stagger: {
							each: 0.01,
							from: "start",
						},
					},
					"currentPanel"
				)
				.fromTo(
					upcomingtWords.map((word) => word.parentNode),
					{
						xPercent: -100,
					},
					{
						onStart: () => {
							texts[upcomingTextPos].classList.add("current");
						},
						duration: 0.6,
						ease: "expo",
						xPercent: 0,
						stagger: {
							each: 0.01,
							from: "start",
						},
					},
					"currentPanel"
				);
		}
	}
}
